<template>
  <div class="create-provider-notification">

    <div
      v-if="!pageLoad"
      class="page-loader"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">Создание уведомления поставщика</h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="danger"
          v-b-modal.confirm-cancel
        >Отменить
        </BButton>
        <BButton
          class="ml-3"
          variant="success"
          @click="createNotification(true)"
        >Сохранить
        </BButton>
        <BButton
          class="ml-3"
          variant="primary"
          @click="createNotification()"
        >Отправить на согласование
        </BButton>
      </div>

    </div>

    <b-tabs
      v-model="tabIndex"
      card
    >

      <b-tab title="Информация">

        <b-row>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Филиал
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-select
                  v-model="sendData.organization_id"
                  :state="$v.sendData.$error ? $v.sendData.organization_id.required : null"
                  :options="organizationsOptions.slice(1)"
                />
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Поставщик
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <div style="height: 29px;line-height: 29px;">
                  {{ user.contr_agent.name }}
                </div>
              </div>
            </div>
          </b-col>

        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px;"
          >Договор поставки
          </div>
          <div class="px-2 py-1 flex-grow-1">
            <b-form-select
              v-model="selectedContract"
              :state="$v.sendData.$error ? $v.sendData.contract_number.required : null"
              :options="contractsOptions"
              :disabled="loadingContacts"
            />
          </div>
        </div>

        <!--                <div class="d-flex table-bordered mb-1">-->
        <!--                    <div-->
        <!--                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"-->
        <!--                        style="width: 250px;"-->
        <!--                    >Этап-->
        <!--                    </div>-->
        <!--                    <div class="px-2 py-1 flex-grow-1">-->
        <!--                        <b-form-select-->
        <!--                            v-model="sendData.contract_stage"-->
        <!--                            :state="$v.sendData.$error ? $v.sendData.contract_stage.required : null"-->
        <!--                            :options="stagesOptions"-->
        <!--                        />-->
        <!--                    </div>-->
        <!--                </div>-->

        <b-row>

          <b-col cols="12">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Фактическая дата доставки
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-datepicker
                  v-model="sendData.date_fact_delivery"
                  class="mb-2"
                  locale="ru-RU"
                  placeholder="Выберите дату"
                  :state="$v.sendData.$error ? $v.sendData.date_fact_delivery.required : null"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
              </div>
            </div>
          </b-col>

          <b-col cols="4">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Адрес доставки
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.delivery_address"
                  :state="$v.sendData.$error ? $v.sendData.delivery_address.required : null"
                  trim
                />
              </div>
            </div>
          </b-col>

          <b-col cols="4">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Марка, гос. номер авто
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.car_info"
                  :state="$v.sendData.$error ? $v.sendData.car_info.required : null"
                  trim
                />
              </div>
            </div>
          </b-col>

          <b-col cols="4">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Телефон водителя
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.driver_phone"
                  :state="$v.sendData.$error ? $v.sendData.driver_phone.required : null"
                  v-mask="'+7 (###) ###-##-##'"
                  trim
                />
              </div>
            </div>
          </b-col>

        </b-row>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light
                                text-muted flex-shrink-0"
                style="width: 250px;"
              >Ответственный
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.responsible_full_name"
                  :state="$v.sendData.$error ? $v.sendData.responsible_full_name.required : null"
                  trim
                />
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px;"
              >Телефон ответственного
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.responsible_phone"
                  :state="$v.sendData.$error ? $v.sendData.responsible_phone.required : null"
                  v-mask="'+7 (###) ###-##-##'"
                  trim
                />
              </div>
            </div>
          </b-col>

        </b-row>

      </b-tab>

      <b-tab title="Позиции">

        <div
          v-if="loading"
        ></div>

        <CreateTable
          v-else-if="orders && orders.length"
          v-model="positions"
          :fields="positionFields"
          :withIterator="true"
          :orders="orders"
          @addRow="addNotificationPosition"
        />

        <div
          v-else
          class="text-center mt-3 h5 text-danger"
        >
          По выбранным данным не найдено ни одного заказа. Пожалуйста проверьте правильность введенных данных.
        </div>

      </b-tab>

    </b-tabs>

    <b-modal
      id="confirm-cancel"
      title="Отмена создания товарной накладной"
      centered
    >
      <p class="m-0">Вы действительно хотите отменить товарную накладную? Внесенные изменения не будут сохранены.</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button
          variant="danger"
          @click="cancelNotification"
        >Да
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CreateTable from "@/components/Tables/Create";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ProviderNotificationCreateForm",
  components: {
    CreateTable,
  },
  validations: {
    sendData: {
      organization_id: { required },
      provider_contr_agent_id: { required },
      contract_number: { required },
      contract_date: { required },
      date_fact_delivery: { required },
      delivery_address: { required },
      car_info: { required },
      driver_phone: { required },
      responsible_full_name: { required },
      responsible_phone: { required },
    },
  },
  props: {
    edit: Boolean,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      user: (state) => state.account.user,
      currentNotification: (state) =>
        state.providerNotifications.currentNotification,
    }),
    ...mapGetters({
      organizationsOptions: "getOrganizationsOptions",
    }),
    contractsOptions() {
      return this.contracts.map((item) => ({
        text: `${item.contract_number} от ${this.$options.filters.parseDate(
          item.contract_date
        )}`,
        value: item,
      }));
    },
  },
  watch: {
    async "sendData.organization_id"() {
      await this.getContracts();
      await this.watchForOrderSendData();
      this.loadingContacts = false;
    },
    selectedContract(val) {
      this.sendData.contract_number = val.contract_number;
      this.sendData.contract_date = moment(val.contract_date).format(
        "YYYY-MM-DD"
      );
    },
    contracts() {
      this.sendData.contract_number = null;
      this.sendData.contract_date = null;
    },
    "sendData.contract_number"() {
      this.watchForOrderSendData();
    },
    "sendData.contract_date"() {
      this.watchForOrderSendData();
    },
  },
  data: () => ({
    pageLoad: false,
    loadingContacts: true,
    tabIndex: 0,
    sendData: {
      action: "draft",
      organization_id: null,
      provider_contr_agent_id: null,
      contract_number: null,
      contract_date: null,
      date_fact_delivery: null,
      delivery_address: null,
      car_info: null,
      driver_phone: null,
      responsible_full_name: null,
      responsible_phone: null,
    },
    positions: [
      {
        order_id: null,
        nomenclature_id: null,
        count: null,
        vat_rate: null,
        price_without_vat: null,
      },
    ],
    selectedContract: null,
    contracts: [],
    count: 0,
    positionFields: [
      {
        key: "order_id",
        label: "№ заявки",
        full: "Номер заявки",
        type: "select",
      },
      {
        key: "order_date",
        label: "Дата заявки",
        full: "Дата заявки",
        type: "disabled",
      },
      {
        key: "nomenclature_id",
        label: "Номенклатура",
        full: "Номенклатура",
        type: "select_nomenclature_from_order",
      },
      {
        key: "mnemocode",
        label: "Мнемокод",
        full: "Мнемокод",
        type: "disabled",
      },
      {
        key: "count",
        label: "Количество",
        full: "Количество",
        type: "number",
      },
      {
        key: "price_without_vat",
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        type: "disabled",
      },
      {
        key: "vat_rate",
        label: "Ставка НДС",
        full: "Ставка НДС",
        type: "select",
      },
    ],
    orders: [],

    loading: false
  }),
  methods: {
    addNotificationPosition() {
      this.positions.push({
        order_id: null,
        nomenclature_id: null,
        count: null,
        vat_rate: null,
        price_without_vat: null,
      });
      this.count++;
    },
    async getContracts() {
      await this.$store
        .dispatch("getProviderNotificationContracts", {
          organization_id: this.sendData.organization_id,
          provider_contr_agent_id: this.sendData.provider_contr_agent_id,
        })
        .then((resp) => {
          if (
            typeof resp === "object" &&
            !Array.isArray(resp) &&
            resp !== null
          ) {
            this.contracts = Object.values(resp);
          } else {
            this.contracts = resp;
          }

          if (this.edit) {
            setTimeout(() => {
              this.sendData.contract_number =
                this.currentNotification.contract_number;
              this.sendData.contract_date =
                this.currentNotification.contract_date;
              this.setCurrentNotificationContract();
            }, 1000);
          }
        });
    },
    async getOrders(data) {
      this.loading = true
      
      await this.$store
        .dispatch("getProviderNotificationsSearchOrders", data)
        .then((resp) => {
          this.orders = resp.map((order) => ({
            id: order.id,
            number: order.number,
            order_date: order.order_date,
            nomenclatures: order.actual_positions.map((position) => ({
              id: position.nomenclature.id,
              name: position.nomenclature.name,
              count: position.nomenclature.count,
              mnemocode: position.nomenclature.mnemocode,
              price: position.price_without_vat,
            })),
          }));
          
          this.loading = false
        });
    },
    watchForOrderSendData() {
      const data = {
        organization_id: this.sendData.organization_id,
        provider_contr_agent_id: this.sendData.provider_contr_agent_id,
        contract_number: this.sendData.contract_number,
        contract_date: this.sendData.contract_date,
      };

      for (let key in data) {
        if (data[key] === null) {
          return false;
        }
      }
      this.getOrders(data);
    },
    cancelNotification() {
      this.$router.push("/provider-notifications");
    },
    createNotification(save = false) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$bvToast.toast("Заполните обязательные поля", {
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
        return;
      }
      this.sendData.action = save ? "draft" : "approve";

      if (this.edit) {
        this.$store
          .dispatch("updateProviderNotification", {
            id: this.id,
            data: { ...this.sendData, positions: this.positions },
          })
          .then(() => {
            this.sendSuccess("Успешно");
          })
          .catch((err) => {
            this.sendError(err);
          });
      } else {
        this.$store
          .dispatch("createProviderNotification", {
            ...this.sendData,
            positions: this.positions,
          })
          .then(() => {
            this.sendSuccess("Успешно");
          })
          .catch((err) => {
            this.sendError(err);
          });
      }
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
      this.$router.push("/provider-notifications");
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },
    setCurrentNotificationContract() {
      this.contractsOptions.forEach((contract) => {
        if (contract.value.contract_number === this.sendData.contract_number) {
          this.selectedContract = contract.value;
        }
      });
    },
  },
  async mounted() {
    this.pageLoad = true;
    this.sendData.provider_contr_agent_id = this.user.contr_agent.id;

    if (this.edit) {
      await this.$store.dispatch("getCurrentNotification", this.id);
      for (let key in this.sendData) {
        if (key !== "action") {
          this.sendData[key] = this.currentNotification[key];
        }
      }
      this.positions = this.currentNotification.positions;
    }
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
