<template>
    <ProviderNotificationCreateForm
        :edit="true"
    />
</template>

<script>
import ProviderNotificationCreateForm from '@/components/ProviderNotification/ProviderNotificationCreateForm'
export default {
    name: "ProviderNotificationEdit",
    components: {
        ProviderNotificationCreateForm
    },
    computed: {
        currentNotification () {
            return this.$store.state.providerNotifications.currentNotification
        },
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Заказ ПО', link: '/' },
                { title: 'Перечень уведомлений поставщика', link: '/provider-notifications' },
                {
                    title: `Уведомление поставщика № ${this.currentNotification.id} от ${this.$options.filters.parseDate(this.currentNotification.date)}`,
                    link: `/provider-notifications/${this.$route.params.id}`
                },
                { title: 'Редактирование' }
            ]
        }
    }
}
</script>
